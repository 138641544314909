import { HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const menu_items: MenuProps['items'] = [
  {
    key: `운송장 관리`,
    icon: <HomeOutlined />,
    label: `운송장 관리`,
    children: [
      {
        key: '/shipping/register',
        label: '운송장 등록',
      },
      {
        key: '/shipping/list',
        label: '운송장 조회',
      },
    ],
  },
  {
    key: '설정',
    icon: <SettingOutlined />,
    label: '설정',
    children: [
      {
        key: '/setting/account',
        label: '계정 관리',
      },
    ],
  },
];

const GLB: FC = () => {
  const navigate = useNavigate();

  return (
    <aside className='w-[210px] flex-[0_0_auto] relative top-[41px]'>
      <nav className='fixed w-[210px] top-[42px] left-0 bottom-0 bg-white border-r-[1px] border-gray-350 z-50'>
        <Menu
          mode='inline'
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          style={{
            height: '100%',
            borderRight: 0,
            fontSize: '12px',
            fontWeight: '600',
          }}
          items={menu_items}
          onClick={(info) => navigate(info.key)}
        />
      </nav>
    </aside>
  );
};

export default GLB;
