import GLB from '@/components/layout/GLB';
import Header from '@/components/layout/Header';
import { FC } from 'react';
import Footer from '../common/Footer';

interface Props {
  children: React.ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <div className='flex flex-col'>
      <Header />
      <div className='flex'>
        <GLB />
        <main className='w-full h-screen mt-10 bg-gray-200'>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
