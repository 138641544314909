import PageHeader from '@/components/layout/PageHeader';
import { useShippingRegisterViewModel } from '@/components/shipping/register/hooks/useShippingRegisterViewModel';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, DatePicker, DatePickerProps, Divider, Input, Radio, RadioChangeEvent, Select } from 'antd';
import { useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { RangePickerProps } from 'antd/es/date-picker';
import ShippingListTable from '@/components/shipping/list/ShippingListTable';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const PACKING_DATE_SELECT_OPTIONS = [{ label: '운송장 등록일', value: 'PACKING_DATE' }];
const PACKING_SEARCH_SELECT_OPTIONS = [{ label: '운송장 번호', value: 'TRACKING_NUMBER' }];

const ShippingListPage = () => {
  const { category_list, is_loading, item_list, submit, total_count } = useShippingRegisterViewModel();
  const [tracking_number, setTrackingNumber] = useState('');
  const [packing_date, setPackingDate] = useState(['', '']);
  const [radio_value, setRadioValue] = useState(1);

  const onChangeRadio = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const handleChangeDate = (
    _: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    setPackingDate(Array.isArray(dateString) ? dateString : [dateString, '']);
  };

  const handleChangeTrackingNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrackingNumber(e.target.value);
  };

  const handleSubmit = () => {
    submit({
      page: 0,
      start_date: packing_date[0],
      end_date: packing_date[1],
      search_value: tracking_number,
    });
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
      (document.activeElement as HTMLElement).blur();
    }
  };

  return (
    <div className='w-full'>
      <PageHeader title='운송장 조회' />
      <div className='flex flex-col pt-[15px] py-5'>
        <div className='overflow-hidden bg-white p-8 pb-0 m-5 rounded-[20px]'>
          <div className='flex flex-col'>
            <div className='flex flex-row gap-[17px] items-center'>
              <span className='text-[50px] leading-[15px]'>🔍</span>
              <span className='text-Heading_20_Bold'>등록된 패킹 시간대만 CCTV로 확인하면 악성 클레임 처리 끝.</span>
            </div>
            <div className='flex flex-row items-center mt-[50px]'>
              <div className='w-[112px] '>
                <span className='text-[15px] font-[500] leading-[28px] text-gray-700'>조회기간</span>
              </div>
              <Select
                defaultValue={'PACKING_DATE'}
                onChange={() => {}}
                style={{ width: 148, marginRight: 7, fontSize: 12 }}
                options={PACKING_DATE_SELECT_OPTIONS}
              />
              <RangePicker onChange={handleChangeDate} />
            </div>
            <div className='flex flex-row items-center mt-[11px]'>
              <div className='w-[112px] '>
                <span className='text-[15px] font-[500] leading-[28px] text-gray-700'>상세조건</span>
              </div>
              <Select
                defaultValue={'TRACKING_NUMBER'}
                onChange={() => {}}
                style={{ width: 148, marginRight: 7, fontSize: 12 }}
                options={PACKING_SEARCH_SELECT_OPTIONS}
              />
              <Input
                className='w-[242px]'
                onChange={handleChangeTrackingNumber}
                onKeyUp={handleKeyUp}
                size='middle'
                placeholder='키워드를 입력해주세요.'
              />
            </div>
            <div className='flex flex-row items-center mt-[11px]'>
              <div className='w-[112px] '>
                <span className='text-[15px] font-[500] leading-[28px] text-gray-700'>고객유형 분류</span>
              </div>
              <Radio.Group onChange={onChangeRadio} value={radio_value}>
                {category_list.map((category) => (
                  <Radio key={category.id} value={category.id}>
                    {category.name}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
            <Divider />
            <div className='flex flex-row items-center mb-[41px]'>
              <Button
                type='primary'
                size='large'
                style={{ fontSize: 12, padding: '0 20px' }}
                className='bg-[#0D99FF] text-Body_12_Medium'
                onClick={handleSubmit}
              >
                조회
              </Button>
              <Button type='text' icon={<ReloadOutlined />}>
                초기화
              </Button>
            </div>
          </div>
        </div>
        <div className='overflow-hidden bg-white p-8 m-5 rounded-[20px]'>
          <div className='flex flex-col gap-[23px]'>
            <div className='flex flex-row justify-between items-center'>
              <div className='flex flex-row gap-[17px] items-center'>
                <span className='text-[50px] leading-[15px]'>👀</span>
                <span className='text-Heading_20_Bold text-[22px]'>운송장 목록 ({total_count || 0}건)</span>
              </div>
              <div className='flex flex-row items-center gap-[15px]'>
                <Button size='large' className='bg-[#363644] text-[#fff] text-Body_13_Medium' style={{ fontSize: 13 }}>
                  고객유형 설정
                </Button>
              </div>
            </div>
            <ShippingListTable item_list={item_list} loading={is_loading} category_list={category_list} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingListPage;
