import api from './fetcher';
import { GetPaymentCardResponse, PaymentCardInput } from './types';

export const getPaymentCard = async (shop_id: number): Promise<GetPaymentCardResponse> => {
  const URL = `/api/v1/shops/${shop_id}/payments/cards`;
  try {
    const response = await api.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postPaymentCard = async (input: PaymentCardInput) => {
  const { shopId, ...rest } = input;
  const URL = `/api/v1/shops/${shopId}/payments/cards`;

  try {
    const response = await api.post(URL, rest);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const putPaymentCard = async (input: PaymentCardInput) => {
  const { shopId, ...rest } = input;
  const URL = `/api/v1/shops/${shopId}/payments/cards`;

  try {
    const response = await api.put(URL, rest);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePaymentCard = async (shop_id: number) => {
  const URL = `/api/v1/shops/${shop_id}/payments/cards`;

  try {
    const response = await api.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
