import api from './fetcher';

export const getCategories = async (shop_id: number) => {
  const URL = `/api/v1/shops/${shop_id}/categories`;
  try {
    const response = await api.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
