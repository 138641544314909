import { Modal } from 'antd';
import { FC } from 'react';

interface Props {
  opened: boolean;
  onClose: () => void;
}

const LoginModal: FC<Props> = ({ opened, onClose }) => {
  const client_id = import.meta.env.VITE_NAVER_CLIENT_ID;
  // const redirect_uri = encodeURI('http://localhost:3000/auth');
  const redirect_uri = encodeURI('https://www.packing-lab.com/auth');
  const state = encodeURI('ok');

  const handleLogin = () => {
    window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}`;
  };

  return (
    <Modal
      title={<span className='text-Heading_20_Bold'>로그인을 완료해주세요</span>}
      open={opened}
      onOk={handleLogin}
      onCancel={onClose}
      okText={<span className='text-Body_13_Medium'>네이버 간편로그인</span>}
      cancelText={<span className='text-Body_13_Medium'>취소</span>}
      okButtonProps={{ className: 'bg-blue-500 text-white', size: 'large' }}
      cancelButtonProps={{ size: 'large' }}
    >
      <p className='text-Body_13_Medium mb-10'>고객 클레임 관리, 딱 3초면 준비가 완료돼요</p>
    </Modal>
  );
};

export default LoginModal;
