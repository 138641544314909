import { putUsers } from '@/api/usersApi';
import { userInfoAtom } from '@/store/auth';
import { Input, Modal } from 'antd';
import { useAtomValue } from 'jotai';
import { FC, useEffect, useState } from 'react';

interface AccountInfoFormModalProps {
  opened: boolean;
  onClose: () => void;
}

const AccountInfoFormModal: FC<AccountInfoFormModalProps> = ({ opened, onClose }) => {
  const user_info = useAtomValue(userInfoAtom);
  const [nick_name, setNickname] = useState('');
  const [phone_number, setPhoneNumber] = useState('010-');

  useEffect(() => {
    if (user_info) {
      setNickname(user_info.name);
    }
  }, [user_info]);

  const handleSubmit = async () => {
    await putUsers({ nick_name, phone_number });
  };

  return (
    <Modal
      title={<span className='text-Heading_20_Bold text-gray-900'>처음이시군요! 정보를 입력해주시겠어요?</span>}
      open={opened}
      onCancel={onClose}
      onOk={handleSubmit}
      okText={<span className='text-Body_13_Medium'>확인</span>}
      cancelText={<span className='text-Body_13_Medium'>취소</span>}
      width={480}
      okButtonProps={{ className: 'bg-blue-500 text-white', size: 'large' }}
      cancelButtonProps={{ size: 'large' }}
    >
      <p className='text-Body_13_Regular text-gray-900 mb-[28px]'>고객 클레임 관리, 딱 3초면 준비가 완료돼요</p>

      <div className='flex flex-col pt-[28px] pb-[55px] gap-[9px]'>
        <div className='flex flex-col gap-[4px]'>
          <span className='text-gray-700 text-Body_12_Medium'>사업자명(상호명)</span>
          <Input
            className='h-[46px]'
            value={nick_name}
            onChange={(e) => setNickname(e.target.value)}
            placeholder='이름을 텍스트로 입력해주세요.'
          />
        </div>
        <div className='flex flex-col gap-[4px]'>
          <span className='text-gray-700 text-Body_12_Medium'>연락처</span>
          <Input className='h-[46px]' value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)} />
        </div>
        <div className='flex flex-col gap-[4px]'>
          <span className='text-gray-700 text-Body_12_Medium'>로그인 계정</span>
          <Input className='h-[46px]' value={user_info?.email} disabled />
        </div>
      </div>
    </Modal>
  );
};

export default AccountInfoFormModal;
