import api from '@/api/fetcher';
import { userInfoAtom } from '@/store/auth';
import { Spin } from 'antd';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectPage = () => {
  const navigate = useNavigate();
  const [_, setInfo] = useAtom(userInfoAtom);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        let code = new URL(window.location.href).searchParams.get('code');
        let state = new URL(window.location.href).searchParams.get('state');
        const res = await api.get(`/api/v1/oauth/callback/naver?code=${code}&state=${state}`);
        if (res.data.code === 'S200') {
          setInfo(res.data.data);
          localStorage.setItem('SID', res.data.data.accessToken);
        }
        navigate('/', { replace: true });
      } catch (error) {
        console.log(error);
        navigate('/', { replace: true });
      }
    };

    getAccessToken();
  }, []);

  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <Spin size='large' />
    </div>
  );
};

export default RedirectPage;
