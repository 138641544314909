import { CaretDownOutlined, RightOutlined } from '@ant-design/icons';
import { Modal, Select } from 'antd';
import { useGetPayments } from './hooks/useGetPayments';
import { useGetPaymentCard } from './hooks/useGetPaymentCard';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { is_card_modal_atom } from '@/store/auth';

interface PaymentManageModalProps {
  opened: boolean;
  onClose: () => void;
}

const PaymentManageModal: React.FC<PaymentManageModalProps> = ({ onClose, opened }) => {
  const setIsCardModalOpen = useSetAtom(is_card_modal_atom);
  const handleCancel = () => {
    onClose();
  };

  const { data: payment_list } = useGetPayments();
  const { data: card_info } = useGetPaymentCard();

  return (
    <Modal
      title={<span className='text-Heading_20_Bold text-gray-900'>결제 관리하기</span>}
      open={opened}
      onCancel={handleCancel}
      onOk={onClose}
      okText={<span className='text-Body_13_Medium'>확인</span>}
      cancelText={<span className='text-Body_13_Medium'>카드관리</span>}
      width={480}
      okButtonProps={{ className: 'bg-blue-500 text-white', size: 'large' }}
      cancelButtonProps={{
        className: 'bg-white',
        size: 'large',
        onClick: () => {
          setIsCardModalOpen(true);
          onClose();
        },
      }}
    >
      <p className='text-Body_13_Regular text-gray-900 mb-[28px]'>패킹랩에 결제된 모든 내역이 이 곳에 모여있어요</p>
      <div className='mt-[43px] flex gap-[4px] flex-col mb-[46px]'>
        <span className='text-Body_12_Regular text-gray-700'>결제내역 (총 {payment_list?.length ?? 0}건)</span>
        <Select
          className='h-[46px] text-Body_13_Regular'
          value={0}
          options={[{ value: 0, label: <span>{card_info?.name ?? '-'}</span> }]}
          suffixIcon={<CaretDownOutlined />}
        />
      </div>
      <div className='flex flex-col gap-1 pb-8'>
        {(payment_list ?? []).map((payment) => (
          <a href={payment.billLink} target='_blank'>
            <div className='flex flex-col py-1 px-4 gap-[4px]'>
              <div className='flex flex-1 justify-between'>
                <p className='text-[13px] text-gray-600'>{payment.name}</p>
                <p className='text-[10px]/[22px]'>{dayjs(payment.dateCreated).format('YYYY/MM/DD')}</p>
              </div>
              <div className='flex flex-1 justify-between'>
                <p className='text-[15px] font-medium'>{payment.price.toLocaleString()}원</p>
                <RightOutlined width={16} height={16} />
              </div>
            </div>
          </a>
        ))}
      </div>
    </Modal>
  );
};

export default PaymentManageModal;
