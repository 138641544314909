import { Input, Modal } from 'antd';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useGetPaymentCard } from './hooks/useGetPaymentCard';
import img_card_active from './images/img_card_active.png';
import img_card_inactive from './images/img_card_inactive.png';
import { useSavePaymentCardMutation } from './hooks/useSavePaymentCardMutation';
import { useAtomValue } from 'jotai';
import { userInfoAtom } from '@/store/auth';
import { deletePaymentCard } from '@/api/paymentsApi';
interface CardInfoManageModalProps {
  opened: boolean;
  onClose: () => void;
}

interface FormInput {
  name: string;
  cardNo1: string;
  cardNo2: string;
  cardNo3: string;
  cardNo4: string;
  cvc: string;
  password: string;
  expire: string;
  personalNo: string;
}

const { confirm, error } = Modal;

const CardInfoManageModal: React.FC<CardInfoManageModalProps> = ({ onClose, opened }) => {
  const {
    control,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm<FormInput>();
  const user_info = useAtomValue(userInfoAtom);
  const { data } = useGetPaymentCard();
  const { mutateAsync: savePaymentCard } = useSavePaymentCardMutation();

  const onSubmit = useCallback(() => {
    handleSubmit(async (input) => {
      const { name, cardNo1, cardNo2, cardNo3, cardNo4, cvc, expire, password, personalNo } = input;
      const data = await savePaymentCard({
        name,
        cardNo: cardNo1 + cardNo2 + cardNo3 + cardNo4,
        cvc,
        expireMonth: expire.split('/')[0],
        expireYear: expire.split('/')[1],
        password,
        personalNo,
        shopId: user_info!.id,
      });

      if (data.code === 'E500') {
        confirm({
          title: '카드 정보가 유효하지 않아요!',
          content: `다시 확인해 주세요. 구독이 취소되어 문제가 발생할 수 있어요. \n ${data.message}`,
          okButtonProps: { className: 'bg-blue-500 text-white' },
          okText: '삭제',
          cancelText: '취소',
          onOk: async () => {
            const data = await deletePaymentCard(user_info!.id);
            if (data.code === 'E500') {
              error({
                title: '카드 삭제에 실패했어요!',
                content: `${data.message}`,
                okText: '확인',
                okButtonProps: { className: 'bg-blue-500 text-white' },
              });
            }
          },
        });
        return;
      }
    })();
  }, [data, user_info]);

  useEffect(() => {
    if (data) {
      const { cardNo, cvc, expireMonth, expireYear, name, personalNo } = data;
      const split_card_no = cardNo.split('-');

      setValue('cardNo1', split_card_no[0], { shouldValidate: true });
      setValue('cardNo2', split_card_no[1]);
      setValue('cardNo3', split_card_no[2]);
      setValue('cardNo4', split_card_no[3]);
      setValue('cvc', cvc);
      setValue('expire', `${expireMonth}/${expireYear}`);
      setValue('name', name);
      setValue('personalNo', personalNo);
    }
  }, [data]);

  const formatExpiryDate = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    const formattedValue = numericValue.slice(0, 4);
    if (formattedValue.length > 2) {
      return formattedValue.slice(0, 2) + '/' + formattedValue.slice(2);
    } else {
      return formattedValue;
    }
  };

  return (
    <Modal
      open={opened}
      onCancel={onClose}
      title={<span className='text-Heading_20_Bold text-gray-900'>카드 정보를 안전하게 관리하세요</span>}
      onOk={onSubmit}
      okText={<span className='text-Body_13_Medium'>확인</span>}
      cancelText={<span className='text-Body_13_Medium'>취소</span>}
      width={480}
      okButtonProps={{ className: 'bg-blue-500 text-white', size: 'large' }}
      cancelButtonProps={{ size: 'large' }}
    >
      <p className='text-Body_13_Regular text-gray-900 mb-[28px]'>
        걱정하지 마세요! 구독하지 않는 동안은 절대 결제되지 않아요
      </p>
      <div className='flex items-center py-[13.5px] px-[9.5px] rounded-[5px] bg-gray-200 gap-[10px] text-Body_13_Medium text-gray-600'>
        {data ? (
          <div className='flex items-center gap-[6px]'>
            <img src={img_card_active} alt='card_active' width={32} />
            <span className='text-red-500 rounded-[5px] border border-solid border-red-500 block text-[9px] px-[5px] py-[3px] leading-[12px] font-semibold'>
              기본
            </span>
            <span className='text-gray-900 text-Body_13_Medium'>{data.name}</span>
          </div>
        ) : (
          <div className='flex items-center gap-[6px]'>
            <img src={img_card_inactive} alt='card_inactive' width={32} />

            <span className='text-gray-900 text-Body_13_Medium'>카드를 등록해주세요.</span>
          </div>
        )}
      </div>
      <div className='flex flex-col pt-[28px] pb-[55px] gap-[9px]'>
        <div className='flex flex-col gap-[4px]'>
          <span className='text-gray-700 text-Body_12_Medium'>성함</span>
          <Controller
            control={control}
            name='name'
            rules={{
              required: '성함을 입력해주세요.',
            }}
            render={({ field }) => (
              <Input
                {...field}
                className='h-[46px]'
                placeholder='성함을 텍스트로 입력해주세요.'
                status={errors.name && 'error'}
              />
            )}
          />
          {errors.name && <p className='text-red-500 text-Body_12_Bold'>{errors.name.message}</p>}
        </div>
        <div className='flex flex-col gap-[4px]'>
          <span className='text-gray-700 text-Body_12_Medium'>카드번호</span>
          <div className='flex gap-1'>
            <Controller
              control={control}
              name='cardNo1'
              rules={{ required: true }}
              render={({ field: { onChange, ...rest } }) => (
                <Input
                  key='cardNo1'
                  className='h-[46px]'
                  {...rest}
                  onChange={(e) => {
                    onChange(e.target.value);
                    if (e.target.value.length === 4) {
                      setFocus('cardNo2');
                    }
                  }}
                  maxLength={4}
                  status={errors.cardNo1 && 'error'}
                />
              )}
            />
            <Controller
              control={control}
              name='cardNo2'
              rules={{ required: true }}
              render={({ field: { onChange, ...rest } }) => (
                <Input
                  key='cardNo2'
                  className='h-[46px]'
                  {...rest}
                  onChange={(e) => {
                    onChange(e.target.value);
                    if (e.target.value.length === 4) {
                      setFocus('cardNo3');
                    }
                  }}
                  maxLength={4}
                  status={errors.cardNo2 && 'error'}
                />
              )}
            />
            <Controller
              control={control}
              name='cardNo3'
              rules={{ required: true }}
              render={({ field: { onChange, ...rest } }) => (
                <Input.Password
                  className='h-[46px]'
                  visibilityToggle={false}
                  maxLength={4}
                  {...rest}
                  onChange={(e) => {
                    onChange(e.target.value);
                    if (e.target.value.length === 4) {
                      setFocus('cardNo4');
                    }
                  }}
                  status={errors.cardNo3 && 'error'}
                />
              )}
            />
            <Controller
              control={control}
              name='cardNo4'
              rules={{ required: true }}
              render={({ field: { onChange, ...rest } }) => (
                <Input
                  className='h-[46px]'
                  {...rest}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  maxLength={4}
                  status={errors.cardNo4 && 'error'}
                />
              )}
            />
          </div>
          {(errors.cardNo1 || errors.cardNo2 || errors.cardNo3 || errors.cardNo4) && (
            <p className='text-red-500 text-Body_12_Bold'>카드번호를 입력해주세요.</p>
          )}
        </div>
        <div className='flex flex-col gap-[4px]'>
          <span className='text-gray-700 text-Body_12_Medium'>CVC 번호</span>
          <Controller
            control={control}
            name='cvc'
            rules={{ required: 'cvc를 입력해주세요.' }}
            render={({ field }) => (
              <Input.Password
                {...field}
                className='h-[46px]'
                placeholder='cvc를 입력해주세요'
                maxLength={3}
                status={errors.cvc && 'error'}
              />
            )}
          />
          {errors.cvc && <p className='text-red-500 text-Body_12_Bold'>{errors.cvc.message}</p>}
        </div>
        <div className='flex flex-col gap-[4px]'>
          <span className='text-gray-700 text-Body_12_Medium'>카드비밀번호 앞2자리</span>
          <Controller
            control={control}
            name='password'
            rules={{ required: '비밀번호를 입력해주세요.' }}
            render={({ field }) => (
              <Input.Password
                {...field}
                className='h-[46px]'
                placeholder='카드 비밀번호 앞 2자리만 입력해주세요'
                maxLength={2}
                status={errors.password && 'error'}
              />
            )}
          />
          {errors.password && <p className='text-red-500 text-Body_12_Bold'>{errors.password.message}</p>}
        </div>
        <div className='flex flex-col gap-[4px]'>
          <span className='text-gray-700 text-Body_12_Medium'>유효기간</span>
          <Controller
            control={control}
            name='expire'
            rules={{
              required: '유효기간을 입력해주세요.',
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                onChange={(e) => onChange(formatExpiryDate(e.target.value))}
                value={value}
                className='h-[46px]'
                placeholder='MM/YY'
                maxLength={5}
                status={errors.expire && 'error'}
              />
            )}
          />
          {errors.expire && <p className='text-red-500 text-Body_12_Bold'>{errors.expire.message}</p>}
        </div>
        <div className='flex flex-col gap-[4px]'>
          <span className='text-gray-700 text-Body_12_Medium'>주민등록번호 앞자리/사업자번호</span>
          <Controller
            control={control}
            name='personalNo'
            rules={{ required: '주민등록번호 앞자리 또는 사업자 번호를 입력해주세요.' }}
            render={({ field }) => (
              <Input
                {...field}
                className='h-[46px]'
                placeholder='주민등록번호 앞자리 또는 사업자번호를 입력해주세요.'
                maxLength={10}
                status={errors.personalNo && 'error'}
              />
            )}
          />
          {errors.personalNo && <p className='text-red-500 text-Body_12_Bold'>{errors.personalNo.message}</p>}
        </div>
      </div>
    </Modal>
  );
};

export default CardInfoManageModal;
