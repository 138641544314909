import { FC, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoginModal from '../auth/LoginModal';
import { useAtom } from 'jotai';
import { userInfoAtom } from '@/store/auth';
import { useLoginState } from '@/hooks/useLoginState';
import { useGetUserDetail } from './hooks/useGetUserDetail';
import { UserOutlined } from '@ant-design/icons';
import { Button, Input, Popover, Spin } from 'antd';
import { useGetUsers } from './hooks/useGetUsers';

const Header: FC = () => {
  const [{ isOpen, onClose, onOpen }] = useLoginState();
  const [search_value, setSearchValue] = useState('');
  const [debounce_value, setDebounceValue] = useState('');
  const [user_info, setUserInfo] = useAtom(userInfoAtom);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('SID');
    localStorage.removeItem('PACKINGLAB_INFO');
    window.location.reload();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebounceValue(search_value);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [search_value, 500]);

  const { data: user_detail } = useGetUserDetail();
  const { data: users, isFetching } = useGetUsers(debounce_value);

  const handleChangeUser = useCallback(
    (id: number) => {
      if (!user_info) return;
      setUserInfo({
        ...user_info,
        id,
      });
    },
    [user_info, navigate],
  );

  const SearchUserForm = () => {
    return (
      <div className='w-[320px]'>
        <Input
          value={search_value}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
        <div className='flex flex-col gap-1'>
          {users?.map((user) => (
            <div className='flex-1 flex justify-between'>
              <span>{user.name}</span>
              <Button onClick={() => handleChangeUser(user.id)}>진입</Button>
            </div>
          ))}
        </div>
        {isFetching && (
          <div>
            <Spin size='large' />
          </div>
        )}
      </div>
    );
  };

  return (
    <header className='fixed w-full flex flex-row justify-between bg-[#0D99FF] px-[16px] py-[8px]'>
      <Link to={'/'}>
        <div className='text-[21px] font-black leading-[25px] text-white'>Packing Lab</div>
      </Link>
      <div className='flex flex-row items-center gap-1'>
        {!!user_detail && user_detail.role === 'ROLE_ADMIN' && (
          <Popover title='유저 검색' trigger={'click'} content={SearchUserForm}>
            <div className='bg-white w-5 h-5 flex justify-center items-center rounded-full cursor-pointer'>
              <UserOutlined />
            </div>
          </Popover>
        )}
        {user_info ? (
          <>
            <div className='text-Caption_11_SemiBold text-white cursor-pointer'>{user_info.email}</div>
            <hr className='w-[1px] h-[10px] mx-1 bg-gray-700 border-none' />
            <div className='text-Caption_11_SemiBold text-white cursor-pointer' onClick={handleLogout}>
              로그아웃
            </div>
          </>
        ) : (
          <>
            <div className='text-Caption_11_SemiBold text-white cursor-pointer'>무료로 이용해보세요</div>
            <hr className='w-[1px] h-[10px] mx-1 bg-gray-700 border-none' />
            <div className='text-Caption_11_SemiBold text-white cursor-pointer' onClick={onOpen}>
              로그인
            </div>
          </>
        )}
      </div>
      <LoginModal opened={isOpen} onClose={onClose} />
    </header>
  );
};

export default Header;
