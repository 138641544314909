import { QueryClient, QueryClientProvider as Provider } from '@tanstack/react-query';
import { FC, PropsWithChildren } from 'react';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 10000,
    },
  },
});

export const QueryClientProvider: FC<PropsWithChildren> = ({ children }) => {
  return <Provider client={client}>{children}</Provider>;
};
