import { postPackings } from '@/api/packingApi';
import PageHeader from '@/components/layout/PageHeader';
import ShippingRegisterTable from '@/components/shipping/register/ShippingRegisterTable';
import { useShippingRegisterViewModel } from '@/components/shipping/register/hooks/useShippingRegisterViewModel';
import { userInfoAtom } from '@/store/auth';
import { ReloadOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, DatePicker, DatePickerProps, Divider, Input } from 'antd';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useState } from 'react';

const ShippingRegisterPage = () => {
  const { category_list, is_loading, item_list, refetch, total_count, refresh_time } = useShippingRegisterViewModel();
  const [tracking_number, setTrackingNumber] = useState('');
  const [packing_date, setPackingDate] = useState<string[] | string>('');
  const user_info = useAtomValue(userInfoAtom);

  const { mutate } = useMutation({
    mutationFn: postPackings,
    onSuccess: () => {
      refetch();
      setTrackingNumber('');
    },
  });

  const handleChangeDate: DatePickerProps['onChange'] = (_, date_string) => {
    setPackingDate(date_string);
  };

  const handleChangeTrackingNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrackingNumber(e.target.value);
  };

  const handleSubmit = () => {
    if (packing_date && tracking_number && user_info !== null) {
      mutate({
        shop_id: user_info.id + '',
        packing_date: packing_date as string,
        tracking_number: tracking_number,
      });
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
      (document.activeElement as HTMLElement).blur();
    }
  };

  return (
    <div className='w-full'>
      <PageHeader title='운송장 등록' />
      <div className='flex flex-col pt-[15px] py-5'>
        <div className='overflow-hidden bg-white p-8 pb-0 m-5 rounded-[20px]'>
          <div className='flex flex-col'>
            <div className='flex flex-row gap-[17px] items-center'>
              <span className='text-[50px] leading-[15px]'>📦</span>
              <span className='text-Heading_20_Bold'>패킹할 때 운송장 바코드만 찍어주면 끝.</span>
            </div>
            <div className='flex flex-row items-center mt-[50px]'>
              <div className='w-[112px] '>
                <span className='text-[15px] font-[500] leading-[28px] text-gray-700'>패킹일시</span>
              </div>
              <DatePicker onChange={handleChangeDate} size='large' minDate={dayjs()} />
            </div>
            <div className='flex flex-row items-center mt-[20px]'>
              <div className='w-[112px] '>
                <span className='text-[15px] font-[500] leading-[28px] text-gray-700'>운송장 번호</span>
              </div>
              <Input
                className='w-[242px]'
                value={tracking_number}
                onChange={handleChangeTrackingNumber}
                onKeyUp={handleKeyUp}
              />
              <Button
                type='primary'
                size='large'
                style={{ fontSize: 12 }}
                className='ml-3 bg-[#0D99FF] text-Body_12_Medium'
                onClick={handleSubmit}
              >
                등록
              </Button>
            </div>
            <div className='mt-[10px]'>
              <span className='text-Body_12_Regular text-gray-700'>
                * 바코드로 스캔시 등록 버튼을 누르지 않아도 자동 기록 생성됩니다.
              </span>
            </div>
            <Divider />
          </div>
        </div>
        <div className='overflow-hidden bg-white p-8 m-5 rounded-[20px]'>
          <div className='flex flex-col gap-[23px]'>
            <div className='flex flex-row justify-between items-center'>
              <div className='flex flex-row gap-[17px] items-center'>
                <span className='text-[50px] leading-[15px]'>📂</span>
                <span className='text-Heading_20_Bold text-[22px]'>오늘 등록한 운송장 ({total_count || 0}건)</span>
              </div>
              <div className='flex flex-row items-center gap-[15px]'>
                <span className='text-Body_13_Medium text-gray-600'>
                  {dayjs(refresh_time).format('YYYY/MM/DD HH:mm:ss')} 기준
                </span>
                <Button
                  type='default'
                  size='large'
                  icon={<ReloadOutlined />}
                  style={{ fontSize: 13 }}
                  onClick={() => {
                    refetch();
                  }}
                >
                  새로고침
                </Button>
              </div>
            </div>
            <ShippingRegisterTable item_list={item_list} loading={is_loading} category_list={category_list} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingRegisterPage;
