import { getSubscription } from '@/api/subscriptionApi';
import { userInfoAtom } from '@/store/auth';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export function useGetSubscriptions() {
  const user_info = useAtomValue(userInfoAtom);

  return useQuery({
    queryKey: ['getSubscriptions', user_info?.id],
    queryFn: () => getSubscription(user_info!.id),
    enabled: user_info !== null,
    select: (data) => {
      return data.data;
    },
  });
}
