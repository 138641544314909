import api from './fetcher';

// 사용자 목록 가져오기
export const getPackings = async (shop_id: number, query_string: string) => {
  const BASE_URL = `/api/v1/shops/${shop_id}/packings`;
  const URL = query_string ? `${BASE_URL}?${query_string}` : BASE_URL;

  try {
    const response = await api.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

type putPackingDataType = {
  id: number;
  categoryIds?: number[];
  memo?: string;
};

export const postPackings = async (packings: { shop_id: string; packing_date: string; tracking_number: string }) => {
  const { shop_id, packing_date, tracking_number } = packings;
  const BASE_URL = `/api/v1/shops/${shop_id}/packings`;

  try {
    const response = await api.post(BASE_URL, {
      packingDate: packing_date,
      trackingNumber: tracking_number,
      channel: 'SMART_STORE',
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const putPackings = async (packings: {
  shop_id: number;
  packing_id: number;
  category_id?: number;
  memo?: string;
}) => {
  const { shop_id, packing_id, category_id, memo } = packings;
  const BASE_URL = `/api/v1/shops/${shop_id}/packings`;
  let data: putPackingDataType = {
    id: packing_id,
  };

  if (!!category_id) {
    data.categoryIds = [category_id];
  }

  if (memo !== '') {
    data.memo = memo;
  }

  try {
    const response = await api.put(BASE_URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
