import { postPaymentCard } from '@/api/paymentsApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useSavePaymentCardMutation() {
  const query_client = useQueryClient();

  return useMutation({
    mutationFn: postPaymentCard,
    onSuccess: (data) => {
      if (data.code !== 'E500') {
        query_client.invalidateQueries({ queryKey: ['getPaymentCard'] });
      }
    },
  });
}
