import { RightOutlined } from '@ant-design/icons';
import { useAtom, useAtomValue } from 'jotai';
import { is_card_modal_atom, userInfoAtom } from '@/store/auth';
import useModalState from '@/hooks/useModalState';
import AccountInfoFormModal from './AccountInfoFormModal';
import CardInfoManageModal from './CardInfoManageModal';
import { useLoginState } from '@/hooks/useLoginState';
import img_card_active from './images/img_card_active.png';
import img_card_inactive from './images/img_card_inactive.png';
import img_account from './images/img_account.png';
import { useGetPaymentCard } from './hooks/useGetPaymentCard';

const AccountInfoCard = () => {
  const user_info = useAtomValue(userInfoAtom);
  const [{ onOpen }] = useLoginState();
  const [{ isOpen: isAccountModalOpen, onClose: onCloseAccountModal, onOpen: onOpenAccountInfoModal }] =
    useModalState(false);
  const [isCardModalOpen, setIsCardModalOpen] = useAtom(is_card_modal_atom);
  const { data } = useGetPaymentCard();

  const handleOpenAccountInfoFormModal = () => {
    if (user_info === null) {
      onOpen();
      return;
    }
    onOpenAccountInfoModal();
  };

  const handleOpenCardManageModal = () => {
    if (user_info === null) {
      onOpen();
      return;
    }
    setIsCardModalOpen(true);
  };

  return (
    <>
      <div className='flex flex-col px-[32px] py-[28px] bg-white rounded-[30px]'>
        <div className='flex gap-[16px] items-center'>
          <span className='text-[25px]'>🔒</span>
          <span className='text-Heading_24_Bold text-[22px] text-gray-900'>계정 관리</span>
        </div>
        <div className='mt-[53px] flex flex-col gap-[16px]'>
          <div
            className='px-[13px] py-[11px] rounded-[10px] bg-[#D9D9D94D] w-[282px] flex items-center justify-between cursor-pointer'
            onClick={handleOpenAccountInfoFormModal}
          >
            <div className='flex gap-[13px] items-center'>
              <div className='bg-white rounded-[4px]'>
                <img src={img_account} width={32} />
              </div>
              <span className='text-Body_14_Medium'>계정 정보</span>
            </div>
            <div className='flex items-center gap-[10px]'>
              <span className='text-Body_14_Medium text-gray-400'>{user_info ? user_info.name : '로그인하세요'}</span>
              <RightOutlined size={16} />
            </div>
          </div>
          <div
            className='px-[13px] py-[11px] rounded-[10px] bg-[#D9D9D94D] w-[282px] flex items-center justify-between cursor-pointer'
            onClick={handleOpenCardManageModal}
          >
            <div className='flex gap-[13px] items-center'>
              <div className='bg-white rounded-[4px]'>
                {data ? <img src={img_card_active} width={32} /> : <img src={img_card_inactive} width={32} />}
              </div>
              <span className='text-Body_14_Medium'>카드 관리</span>
            </div>
            <div className='flex items-center gap-[10px]'>
              <RightOutlined size={16} />
            </div>
          </div>
        </div>
      </div>
      <AccountInfoFormModal opened={isAccountModalOpen} onClose={onCloseAccountModal} />
      <CardInfoManageModal opened={isCardModalOpen} onClose={() => setIsCardModalOpen(false)} />
    </>
  );
};

export default AccountInfoCard;
