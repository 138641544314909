import { getPayments } from '@/api/subscriptionApi';
import { userInfoAtom } from '@/store/auth';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export const useGetPayments = () => {
  const user_info = useAtomValue(userInfoAtom);

  return useQuery({
    queryKey: ['getPayments', user_info?.id],
    queryFn: () => getPayments({ shopId: user_info!.id }),
    enabled: user_info !== null,
  });
};
