import Redirect from '@/pages/auth';
import HomePage from '@/pages/home';
import RootPage from '@/pages/root';
import ShippingListPage from '@/pages/shipping/list';
import ShippingRegisterPage from '@/pages/shipping/register';
import { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage />,
    errorElement: <>404</>,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/shipping/register',
        element: <ShippingRegisterPage />,
      },
      {
        path: '/shipping/list',
        element: <ShippingListPage />,
      },
      {
        path: '/setting/account',
        element: <HomePage />,
      },
    ],
  },
  {
    path: '/auth',
    element: <Redirect />,
  },
]);

export const RootRouter: FC = () => {
  return <RouterProvider router={router} />;
};
