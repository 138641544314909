import { QueryClientProvider } from '@/context';
import { RootRouter } from '@/routes/indext';
import { ConfigProvider } from 'antd';
import { FC, Suspense } from 'react';

export const App: FC = () => {
  return (
    <QueryClientProvider>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Pretendard, sans-serif',
          },
        }}
      >
        <Suspense fallback={<>로딩중...</>}>
          <RootRouter />
        </Suspense>
      </ConfigProvider>
    </QueryClientProvider>
  );
};
