import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
}

const PageHeader: FC<Props> = ({ title }) => {
  return (
    <div className='w-full py-[29px] px-[44px] border-b-[1px] border-gray-350 bg-white'>
      <Link to='/'>
        <h1 className='text-Heading_24_Bold text-gray-900'>{title}</h1>
      </Link>
    </div>
  );
};

export default PageHeader;
