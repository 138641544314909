import { getCategories } from '@/api/categoriesApi';
import { getPackings } from '@/api/packingApi';
import { userInfoAtom } from '@/store/auth';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { isEmpty, toNumber } from 'lodash-es';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface GetShippingRegisterPageListQueryVariables {
  search_value?: string | null;
  page: number;
  start_date?: string | null;
  end_date?: string | null;
}

export interface Category {
  id: number;
  name: string;
  color: string;
  shopId: number;
}

interface Packing {
  id: number;
  packingDate: string;
  trackingNumber: string;
  channel: string;
  categories: Category[];
  memo: string;
  packingTime: string;
}

interface useShippingRegisterViewModel {
  category_list: Category[];
  item_list: Packing[];
  total_count: number;
  refetch: () => void;
  submit: (values: GetShippingRegisterPageListQueryVariables) => void;
  is_loading: boolean;
  form_values: GetShippingRegisterPageListQueryVariables;
  refresh_time: Date | null;
}

const DATE_FIELD_VALUES = {
  sortField: 'PACKINGDATE',
  direction: 'ASC',
  limit: 30,
};

export const INITIAL_FORM_VALUES: GetShippingRegisterPageListQueryVariables = {
  page: 0,
  search_value: null,
  start_date: null,
  end_date: null,
};

const SEARCH_FIELD_VALUES = {
  searchKey: 'TRACKING_NUMBER',
  valid: true,
};

export function useShippingRegisterViewModel(): useShippingRegisterViewModel {
  const location = useLocation();
  const navigate = useNavigate();
  const user_info = useAtomValue(userInfoAtom);
  const [refresh_time, setRefreshTime] = useState<Date | null>(null);

  const page_state = useMemo(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (isEmpty(query))
      return {
        ...INITIAL_FORM_VALUES,
        ...DATE_FIELD_VALUES,
      };
    const { page, search_value, start_date, end_date } = query || {};
    return {
      ...DATE_FIELD_VALUES,
      page: toNumber(page) as number,
      ...(search_value && {
        search_value: search_value as string,
        ...SEARCH_FIELD_VALUES,
      }),
      startDate: start_date as string,
      endDate: end_date as string,
    };
  }, [location.search]);

  const { data: category_list, isFetching: category_loading } = useQuery({
    queryKey: ['getCategoryList', user_info?.id],
    queryFn: () => getCategories(user_info!.id),
    enabled: user_info !== null,
  });

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['getPackingList', user_info?.id, page_state],
    queryFn: () => getPackings(user_info!.id, qs.stringify(page_state)),
    enabled: user_info !== null,
  });

  useEffect(() => {
    if (data) {
      setRefreshTime(new Date());
    }
  }, [data]);

  const { itemList: item_list, totalCount: total_count } = data?.data || {};

  const submit = (values: GetShippingRegisterPageListQueryVariables) => {
    const new_search = `?${qs.stringify(
      {
        ...page_state,
        ...values,
      },
      { skipNulls: true },
    )}`;
    if (new_search === location.search) {
      refetch();
    } else {
      navigate(`${location.pathname}${new_search}`);
    }
  };

  const is_loading = category_loading || isFetching;
  return {
    category_list: category_list ?? [],
    item_list: item_list ?? [],
    total_count,
    refetch,
    submit,
    is_loading,
    form_values: page_state,
    refresh_time,
  };
}
