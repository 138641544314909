import PageHeader from '@/components/layout/PageHeader';
import { FC } from 'react';
import img_login_main from './images/img_login_main.png';
import AccountInfoCard from '@/components/home/AccountInfoCard';
import MembershipInfoCard from '@/components/home/MemberShipInfoCard';
import { useAtomValue } from 'jotai';
import { userInfoAtom } from '@/store/auth';

const HomePage: FC = () => {
  const user_info = useAtomValue(userInfoAtom);
  return (
    <div className='w-full'>
      <PageHeader title='🔥 악성 클레임에 시달리던 당신, 고객관리할 시간도 아까우니까.' />
      <div className='flex flex-col p-[44px] gap-[30px]'>
        <div className='flex gap-[43px]'>
          <AccountInfoCard />
          <MembershipInfoCard />
        </div>
        {!user_info && (
          <div className='rounded-[10px] w-[735px]'>
            <img src={img_login_main} alt='' />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
