import { getUsers } from '@/api/usersApi';
import { useQuery } from '@tanstack/react-query';

export const useGetUsers = (search_value: string) => {
  return useQuery({
    queryKey: ['users', search_value],
    queryFn: () => getUsers(search_value),
    enabled: search_value !== '',
    select: (data) => data.data,
  });
};
