import api from './fetcher';
import { GetUserDetailResponse, GetUsersResponse } from './types';

const BASE_URL = '/api/v1/users';

interface PutUsersProps {
  nick_name: string;
  phone_number: string;
}

export const getUsers = async (query_string: string): Promise<GetUsersResponse> => {
  try {
    const response = await api.get(`${BASE_URL}?q=${query_string}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const putUsers = async ({ nick_name, phone_number }: PutUsersProps) => {
  const data = {
    nickName: nick_name,
    phone: phone_number,
  };
  try {
    const response = await api.put(BASE_URL, data);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getUserDetail = async (shopId: number): Promise<GetUserDetailResponse> => {
  const URL = BASE_URL + `/${shopId}`;
  try {
    const response = await api.get(URL);
    return response.data;
  } catch (e) {
    throw e;
  }
};
