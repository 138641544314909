import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE,
});

api.interceptors.request.use((config) => {
  const sid = localStorage.getItem('SID');
  if (sid !== null) {
    config.headers.Authorization = `Bearer ${sid}`;
  }

  return config;
});

api.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      localStorage.removeItem('SID');
      localStorage.removeItem('PACKINGLAB_INFO');
      window.location.href = '/';
    }
    return res;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('SID');
      localStorage.removeItem('PACKINGLAB_INFO');
      window.location.href = '/';
    }
  },
);

export default api;
