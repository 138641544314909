import { FC, useEffect, useState } from 'react';
import { Dropdown, Table } from 'antd';
import { EditableCell, EditableRow } from '@/components/common/EditableRows';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putPackings } from '@/api/packingApi';
import { useAtomValue } from 'jotai';
import { userInfoAtom } from '@/store/auth';
import { WarningFilled } from '@ant-design/icons';
import { Category } from '../register/hooks/useShippingRegisterViewModel';
import { toNumber } from 'lodash';

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

type DataType = {
  id: number;
  trackingNumber: string;
  packingDate: string;
  packingTime: string;
  memo: string;
  categories?: {
    id: number;
    shopId: number;
    name: string;
    color: string;
  }[];
};

interface Props {
  item_list: DataType[];
  loading: boolean;
  category_list: Category[];
}

const ShippingListTable: FC<Props> = ({ item_list, loading, category_list }) => {
  const user_info = useAtomValue(userInfoAtom);
  const [data_source, setDataSource] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const query_client = useQueryClient();

  useEffect(() => {
    setDataSource(item_list);
  }, [item_list]);

  const { mutate } = useMutation({
    mutationFn: putPackings,
    onSuccess: () => {
      query_client.invalidateQueries({ queryKey: ['getPackingList'] });
    },
  });

  const items = category_list.map(({ id, name }) => ({ key: id, label: name }));

  const handleClickDropdown = (packing_id: number, category_id: string) => {
    if (user_info) {
      mutate({ shop_id: user_info.id, packing_id, category_id: toNumber(category_id) });
    }
  };

  const columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: '번호',
      dataIndex: 'id',
      key: 'id',
      width: 75,
      align: 'center',
    },
    {
      title: '패킹일',
      dataIndex: 'packingDate',
      key: 'packingDate',
      width: 120,
    },
    {
      title: '패킹시간',
      dataIndex: 'packingTime',
      key: 'packingTime',
      width: 120,
    },
    {
      title: '운송장번호',
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
      width: 180,
      render: (_, { trackingNumber }) => <span className='text-[#0D99FF]'>{trackingNumber}</span>,
    },
    {
      title: '패킹메모',
      dataIndex: 'memo',
      key: 'memo',
      editable: true,
    },
    {
      title: '고객분류유형',
      key: 'action',
      dataIndex: 'action',
      width: 150,
      render: (_, { id, categories }) => (
        <Dropdown menu={{ items, onClick: ({ key }) => handleClickDropdown(id, key) }}>
          <span className={`cursor-pointer`}>{categories.length > 0 ? categories?.[0]?.name : '분류 추가'}</span>
        </Dropdown>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSave = (row: DataType) => {
    const newData = [...data_source];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    if (user_info) {
      mutate({ shop_id: user_info.id, packing_id: row.id, memo: row.memo });
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const editable_columns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const rows =
    data_source.map(({ id, packingDate, packingTime, trackingNumber, memo, categories }) => ({
      key: id,
      id,
      packingDate,
      packingTime,
      trackingNumber,
      memo,
      categories,
    })) ?? [];
  return (
    <Table
      rowSelection={rowSelection}
      components={components}
      rowClassName={() => 'editable-row'}
      bordered
      dataSource={rows}
      columns={editable_columns as ColumnTypes}
      pagination={{ position: ['bottomCenter'] }}
      loading={loading}
      locale={{
        emptyText: (
          <div className='flex items-center justify-center py-[150px]'>
            <WarningFilled style={{ fontSize: 50 }} />
            <span className='text-Heading_20_Bold ml-[26px]'>아직 등록된 운송장이 없어요</span>
          </div>
        ),
      }}
    />
  );
};

export default ShippingListTable;
