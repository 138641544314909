import api from './fetcher';
import { GetPaymentInput, GetPaymentsResponse, GetSubscriptionResponse, SubscriptionInput } from './types';

export const getSubscription = async (shop_id: number): Promise<GetSubscriptionResponse> => {
  const URL = `/api/v1/shops/${shop_id}/subscriptions`;
  try {
    const response = await api.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postSubscription = async (input: SubscriptionInput) => {
  const { shopId } = input;
  const URL = `/api/v1/shops/${shopId}/subscriptions`;

  try {
    const response = await api.post(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const putSubscriptionCancel = async (input: SubscriptionInput) => {
  const { shopId } = input;
  const URL = `/api/v1/shops/${shopId}/subscriptions/cancel`;

  try {
    const response = await api.put(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPayments = async (input: GetPaymentInput): Promise<GetPaymentsResponse[]> => {
  const { shopId, limit = 100, offset = 0 } = input;
  const URL = `/api/v1/shops/${shopId}/subscriptions/payments`;

  try {
    const response = await api.get(URL, { params: { limit, offset } });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const putSubscriptionCancelCanceling = async (shopId: number) => {
  const URL = `/api/v1/shops/${shopId}/subscriptions/cancel/canceling`;

  try {
    const response = await api.put(URL);
    return response.data;
  } catch (e) {
    throw e;
  }
};
