import { RightOutlined } from '@ant-design/icons';
import useModalState from '@/hooks/useModalState';
import SubscribeFormModal from './SubscribeFormModal';
import PaymentManageModal from './PaymentManageModal';
import { useAtomValue } from 'jotai';
import { userInfoAtom } from '@/store/auth';
import { useLoginState } from '@/hooks/useLoginState';
import img_subscribe from './images/img_subscribe.png';
import img_subscribing from './images/img_subscribing.png';
import img_unsubscribing from './images/img_unsubscribe.png';
import img_card_active from './images/img_card_active.png';
import { useGetSubscriptions } from './hooks/useGetSubscriptions';

const MembershipInfoCard = () => {
  const user_info = useAtomValue(userInfoAtom);
  const [{ onOpen }] = useLoginState();
  const [{ isOpen: isSubscribeModalOpen, onClose: onCloseSubscribeModal, onOpen: onOpenSubscribeInfoModal }] =
    useModalState(false);

  const [{ isOpen: isPaymentManageModalOpen, onClose: onClosePaymentManageModal, onOpen: onOpenPaymentManageModal }] =
    useModalState(false);

  const { data: subscriptions } = useGetSubscriptions();

  const handleOpenSubscribeInfoModal = () => {
    if (user_info === null) {
      onOpen();
      return;
    }
    onOpenSubscribeInfoModal();
  };

  const handleOpenPaymentManageModal = () => {
    if (user_info === null) {
      onOpen();
      return;
    }
    onOpenPaymentManageModal();
  };

  return (
    <>
      <div className='flex flex-col px-[32px] py-[28px] bg-white rounded-[30px]'>
        <div className='flex gap-[16px] items-center'>
          <span className='text-[25px]'>💳</span>
          <span className='text-Heading_24_Bold text-[22px] text-gray-900'>멤버쉽 관리</span>
        </div>
        <div className='mt-[53px] flex flex-col gap-[16px]'>
          <div
            className='px-[13px] py-[11px] rounded-[10px] bg-[#D9D9D94D] w-[282px] flex items-center justify-between cursor-pointer'
            onClick={handleOpenSubscribeInfoModal}
          >
            <div className='flex gap-[13px] items-center'>
              <div className='bg-white rounded-[4px]'>
                {user_info?.isSubscribing ? (
                  <img src={img_subscribing} width={32} />
                ) : !user_info?.isSubscribing && subscriptions?.status === 'CANCELLING' ? (
                  <img src={img_unsubscribing} width={32} />
                ) : (
                  <img src={img_subscribe} width={32} />
                )}
              </div>
              <p className='text-Body_14_Medium'>
                {user_info?.isSubscribing ? (
                  <>
                    <span className='text-primary'>구독 중!</span> 서비스 이용 관리하기
                  </>
                ) : !user_info?.isSubscribing && subscriptions?.status === 'CANCELLING' ? (
                  <>
                    <span className='text-primary'>다시</span> 4,900원으로 구독하기
                  </>
                ) : (
                  <>
                    <span className='text-primary'>1개월 무료</span> 구독하기
                  </>
                )}
              </p>
            </div>
          </div>
          <div
            className='px-[13px] py-[11px] rounded-[10px] bg-[#D9D9D94D] w-[282px] flex items-center justify-between cursor-pointer'
            onClick={handleOpenPaymentManageModal}
          >
            <div className='flex gap-[13px] items-center'>
              <div className='bg-white rounded-[4px]'>
                <img src={img_card_active} width={32} />
              </div>
              <span className='text-Body_14_Medium'>결제 내역</span>
            </div>
            <div className='flex items-center gap-[10px]'>
              <RightOutlined size={16} />
            </div>
          </div>
        </div>
      </div>
      <SubscribeFormModal opened={isSubscribeModalOpen} onClose={onCloseSubscribeModal} />
      <PaymentManageModal opened={isPaymentManageModalOpen} onClose={onClosePaymentManageModal} />
    </>
  );
};

export default MembershipInfoCard;
