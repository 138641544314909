import { postSubscription } from '@/api/subscriptionApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useSubscriptionsMutation() {
  const query_client = useQueryClient();
  return useMutation({
    mutationFn: postSubscription,
    onSuccess: () => {
      query_client.invalidateQueries({ queryKey: ['getSubscriptions'] });
    },
  });
}
