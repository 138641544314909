const Footer = () => {
  return (
    <div className='text-Caption_11_Regular px-11 py-4 pl-[220px] bg-gray-200 border-t-gray-600 border-t-[1px]'>
      상호: 어퍼 | 대표: 박주광 ㅣ 영업시간 10시~18시 ㅣ 모든 상담은 이메일로 받고 있습니다. | 이메일:
      packing.laboratory@gmail.com <br />
      주소: 경기도 고양시 일산서구 송포로164번길 127-21 l 031-994-0330 | 사업자등록번호: 1283825530 | 통신판매:
      제2018-고양일산서-0146호
    </div>
  );
};

export default Footer;
