import { CaretDownOutlined } from '@ant-design/icons';
import { Checkbox, Modal, Select } from 'antd';
import { useState } from 'react';
import { useGetSubscriptions } from './hooks/useGetSubscriptions';
import { useGetPaymentCard } from './hooks/useGetPaymentCard';
import dayjs from 'dayjs';
import { useSubscriptionsMutation } from './hooks/useSubscriptionsMutation';
import { useSubscriptionsCancelMutation } from './hooks/useSubscriptionsCancelMutation';
import { useAtomValue } from 'jotai';
import { userInfoAtom } from '@/store/auth';

interface SubscribeFormModalProps {
  opened: boolean;
  onClose: () => void;
}

const { info, confirm } = Modal;

const SubscribeFormModal: React.FC<SubscribeFormModalProps> = ({ onClose, opened }) => {
  const user_info = useAtomValue(userInfoAtom);
  const [is_check, setIsCheck] = useState(false);
  const { data: cards } = useGetPaymentCard();
  const { data: subscriptions } = useGetSubscriptions();
  const { mutate: createSubscription, isPending: is_loading_create_sub } = useSubscriptionsMutation();
  const { mutate: subscriptionCancel, isPending: is_loading_cancel_sub } = useSubscriptionsCancelMutation();

  const handleSubmit = () => {
    if (subscriptions?.status === 'SUBSCRIBED') {
      confirm({
        title: '정말 해지하시겠어요?',
        content: (
          <>
            다시 구독을 시작해도 지금까지의 패킹 정보는 다시 불러올 수 없게 되요. <br /> 이 가격 유지하려면 서버 비용을
            아껴야하거든요 ㅠㅠ
          </>
        ),
        okText: '그래도 해지하기',
        cancelText: '구독 유지하기',
        onOk() {
          subscriptionCancel({ shopId: user_info!.id });
        },
      });
    } else {
      createSubscription({ shopId: user_info!.id });
    }
  };

  const loading = is_loading_cancel_sub || is_loading_create_sub;

  if (!cards) {
    return (
      <Modal
        open={opened}
        onCancel={onClose}
        title='카드 정보를 등록해야 구독이 가능해요'
        onOk={() => {
          onClose();
        }}
        okText='카드등록'
        cancelText='닫기'
      >
        <div>
          <span>카드 등록한다고 절대 돈이 나가지 않아요! 10초면 카드 등록 완료!</span>
        </div>
      </Modal>
    );
  }

  const handleOpenServicePolicy = () => {
    info({
      width: 480,
      title: '서비스 이용정책',
      okText: '닫기',
      okButtonProps: { className: 'bg-gray-200 text-gray-900 text-Body_13_Medium' },
      styles: { body: { width: '100%' } },
      content: (
        <div className='overflow-y-auto h-[400px] bg-gray-100 mt-[20px] border border-gray-200 w-full p-[24px]'>
          <span className='text-Heading_17_SemiBold text-[#242729]'>서비스의 이용</span>
          <p className='text-Body_14_Regular text-gray-700'>
            <ol className='list-decimal list-inside'>
              <li>
                "패킹랩”(이하 ‘서비스’) 이용요금 과금 시작일은 무료 체험을 할 경우, 무료체험 만료일의 익일(이하
                ‘과금일')이며, 무료 체험을 하지 않을 경우, 구독 신청을 한 당일입니다.
                <ul className='list-disc list-inside'>
                  <li>
                    구독 신청 후 결제할 카드를 등록하시면 플랜 선택 메뉴가 오픈되며, 해당일 내에 등록한 카드를 통해
                    결제가 이루어집니다.
                  </li>
                  <li>
                    카드 미등록으로 인해 구독 신청 후 메뉴를 확인하지 못하실 경우, 이는 이용자의 귀책으로 간주되며,
                    때문에 해당 기간 또한 과금에 해당되는 사용 기간에 포함됩니다.
                  </li>
                </ul>
              </li>
              <li>이용 시작일인 구독 신청 당일부터 데이터의 적재와 조회가 제공됩니다.</li>
              <li>
                ‘충성 고객 관리' 의 데이터는 프리미엄 플랜 구독시 제공되며, 서비스 사용 시작일 익일부터 조회가
                가능합니다.
              </li>
              <li>
                계약 기간동안 이용자가 등록해 둔 신용 또는 체크카드로 매월 과금일에 적용된 플랜에 따라 정기 결제가
                이루어집니다.
              </li>
              <li>회사는 이용자와 협의하여 서비스 이용요금 결제 이전에 미리 서비스를 제공할 수 있습니다.</li>
            </ol>
            <span className='text-Heading_17_SemiBold text-[#242729]'>서비스 이용 요금</span>
            <ol className='list-decimal list-inside'>
              <li>
                회사가 제공하는 서비스 및 이에 대한 가격, 결제 방법 등 구체적인 사항은 메일 등을 통해 게시되며, 서비스의
                범위 및 내용, 이용요금 등 관련 제반 사항은 회사의 정책에 따라 수시로 변경될 수 있습니다.
              </li>
              <li>
                서비스 이용 요금은 다음 각 호의 요금을 포함하며, 구체적인 과금 기준에 대해서는 다음의 계약 사항에
                고지합니다.
              </li>
              <li>1개월 계약 요금 (정가)</li>
              <li>
                부가서비스 요금: 회사가 이용자에게 부가 기능 추가 등으로 인한 서비스 추가로 제공하는 대가로 받는 요금을
                뜻합니다.
              </li>
              <li>회사와 별도 과금 기준을 정한 경우 해당 내용이 고지한 내용보다 우선하여 적용됩니다.</li>
              <li>서비스의 이용 요금에 대해 회사는 일시적으로 추가 할인을 제공하거나 이를 종료할 수 있습니다.</li>
              <li>
                서비스 이용 도중 요금 과금 기준이 변경될 경우, 기본적으로는 이미 계약된 플랜 금액에 대해 추가로 비용을
                청구하거나 환불하지 않습니다. 다만 관련 세부 사항은 변경될 수 있으며, 변경사항이 발생한 경우 이를 사전에
                공시합니다.
              </li>
            </ol>
            <span className='text-Heading_17_SemiBold text-[#242729]'>이용 요금의 결제</span>
            <ol className='list-decimal list-inside'>
              <li>
                서비스 이용 결제는 선불결제를 원칙으로 하며, 계약 기간에 대해 월별로 회차 시작일인 정기 과금일에 과금이
                됩니다.
              </li>
              <li>플랜의 계약 및 과금의 시작일 산정 기준은 아래와 같습니다.</li>
              <li>무료체험 중인 경우: 무료체험 마지막날 익일</li>
              <li>무료체험중이 아닌 경우: 구독 신청일 (계약 시작일)</li>
              <li>
                월별 정기과금일은 기본적으로 구독 신청일자로 자동 설정됩니다. 단, 특정 월에 결제일이 존재하지 않는 경우,
                해당 월에 한하여 말일에 과금일이 부여되며 때문에 유동적일 수 있습니다.
              </li>
              <li>예시1) 3/3 구독 시, 4/3, 5/3, … 로 정기 과금됨.</li>
              <li>예시2) 1/31 구독 시, 2/28 또는 2/29, 3/31, 4/30, … 로 정기 과금 됨.</li>
            </ol>
            <span className='text-Heading_17_SemiBold text-[#242729]'>결제 실패 시의 대응</span>
            <ol className='list-decimal list-inside'>
              <li>
                구독 신청일 또는 정기 과금일 당일 내에 신청/사용중인 플랜에 대해 등록된 카드로 결제가 자동으로
                이루어집니다.
              </li>
              <li>결제에 실패할 경우, 등록된 카드 정보로 +2일까지 매일 주기적으로 재결제를 시도합니다.</li>
              <li>
                위 지정한 기간까지 재결제에 실패할 경우, 해당 회차의 사용은 즉시 중지되며 계약 중도 해지로 간주합니다.
              </li>
            </ol>
            <span className='text-Heading_17_SemiBold text-[#242729]'>이용 계약의 중도 해지</span>
            <ol className='list-decimal list-inside'>
              <li>계약 기간 중 구독관리 페이지에서 서비스의 계약 해지 신청을 할 수 있습니다.</li>
              <li>
                해지 신청을 할 경우 해당 회차까지의 사용은 유지되며, 다음 회차부터 해지가 적용됩니다. 이로 인해 해당
                회차의 종료일 익일(즉, 다음회차의 정기과금일)부터 서비스를 사용하실 수 없습니다.
              </li>
              <li>
                개인정보 보호법에 의거하여 해지 고객의 정보는 해지 시점으로부터 30일 이후 재결제가 발생하지 않는 경우
                지체없이 삭제하므로, 데이터의 복구와 복원을 요청할 수 없습니다. 고객은 이 점을 숙지하여 해지 시점에
                신중히 의사결정하여야 합니다.
              </li>
              <li>계약 기간 중 마지막 회차 외의 기간에 계약을 중도 해지하는 경우, 위약금이 적용됩니다.</li>
              <li>해지 신청에 대한 철회는 해지 적용 예정일 전날까지 구독관리 페이지에서 신청하실 수 있습니다.</li>
            </ol>
            <span className='text-Heading_17_SemiBold text-[#242729]'>위약금의 계산 및 결제</span>
            <ol className='list-decimal list-inside'>
              <li>
                계약 중도 해지로 인한 위약금은 해지가 적용되는 일자(즉, 이용중인 회차의 종료일 익일이며 기존 다음회차의
                정기 과금일)에 등록된 카드로 일시불 자동 결제가 이루어집니다.
              </li>
              <li>
                계약기간을 기준으로 사용한 회차*에 대해 정가** 대비 할인 받은 금액의 총합이 위약금으로 적용됩니다.
              </li>
              <li>
                1개월 단위의 계약은 해당 회차가 마지막 회차인 것으로 간주되어, 해지 시 별도의 위약금이 발생하지
                않습니다.
              </li>
            </ol>
            <span className='text-Heading_17_SemiBold text-[#242729]'>이용 계약의 갱신</span>
            <ol className='list-decimal list-inside'>
              <li>
                지정된 계약 만료일이 도래하기 전 이용자가 해지 신청을 하지 않을 경우, 해당 플랜의 계약 기간 단위로
                계약의 자동 갱신이 이루어집니다.
              </li>
            </ol>
            <p>부칙</p>
            <p>이 약관은 2024년 2월 1일부터 적용됩니다.</p>
          </p>
        </div>
      ),
    });
  };

  return (
    <Modal
      open={opened}
      onCancel={onClose}
      title={<span className='text-Heading_20_Bold text-gray-900'>구독 상태 관리하기</span>}
      onOk={handleSubmit}
      okText={
        <span className='text-Body_13_Medium'>
          {subscriptions === null
            ? '1개월 무료 사용'
            : subscriptions?.status === 'SUBSCRIBED'
            ? '구독 해지하기'
            : '구독 시작하기'}
        </span>
      }
      cancelText={<span className='text-Body_13_Medium'>닫기</span>}
      width={480}
      okButtonProps={{ className: 'bg-blue-500 text-white', size: 'large', disabled: !is_check, loading: loading }}
      cancelButtonProps={{ className: 'bg-gray-200', size: 'large' }}
    >
      <div className='flex gap-[16px] items-center mt-[46px]'>
        {subscriptions === null ? (
          <>
            <span className='text-[25px]'>❌</span>
            <span className='text-[22px] leading-[27px] font-bold  text-red-500'>클레임 무적</span>
            <span className='px-[5px] py-[3px] rounded-[4px] border border-red-500 text-red-500 text-Caption_10_SemiBold'>
              미구독
            </span>
          </>
        ) : subscriptions?.status === 'SUBSCRIBED' ? (
          <>
            <span className='text-[25px]'>✅</span>
            <span className='text-[22px] leading-[27px] font-bold  text-[#23AE50]'>클레임 무적</span>
            <span className='px-[5px] py-[3px] rounded-[4px] border border-[#23AE50] text-[#23AE50] text-Caption_10_SemiBold'>
              구독중
            </span>
          </>
        ) : subscriptions?.status === 'CANCELLING' ? (
          <>
            <span className='text-[25px]'>🥹</span>
            <span className='text-[22px] leading-[27px] font-bold  text-[#FFA93B]'>돌아와요 그대여</span>
            <span className='px-[5px] py-[3px] rounded-[4px] border border-[#FFA93B] text-[#FFA93B] text-Caption_10_SemiBold'>
              구독정지
            </span>
          </>
        ) : null}
      </div>
      <p className='text-Body_13_Regular'>
        {subscriptions === null ? (
          <>
            이 패킹랩은 영국에서 최초로 시작되어 악성 고객에게 시달리는 셀러에게 행운을 주었고 지금 당신에게로 옮겨진 이
            편지는 4일 안에 당신이 구독을 시작해야 ...
          </>
        ) : subscriptions?.status === 'SUBSCRIBED' ? (
          <>{`${dayjs(subscriptions?.dateCreated).format('YYYY년 M월 dd일')}부터 함께 고객을 위해 고민하고 있어요!`}</>
        ) : (
          <>{`${dayjs(subscriptions?.dateCancelReserved ?? new Date()).format(
            'YYYY년 M월 D일',
          )} 이후로 고객 관리가 어렵지는 않으셨나요?`}</>
        )}
      </p>
      <div className='mt-[43px] flex gap-[4px] flex-col'>
        <span className='text-Body_12_Regular text-gray-700'>구독 상품 선택하기</span>
        <Select
          className='h-[46px] text-Body_13_Regular'
          value={0}
          options={[{ value: 0, label: <span>베이직 (월 4,900원)</span> }]}
          disabled
          suffixIcon={<CaretDownOutlined />}
        />
      </div>
      <div className='mt-[25px] mb-[40px] flex'>
        <Checkbox checked={is_check} onChange={(e) => setIsCheck(e.target.checked)}>
          [필수] 서비스 이용정책 동의
        </Checkbox>
        <span className='text-blue-500 cursor-pointer' onClick={handleOpenServicePolicy}>
          읽기
        </span>
      </div>
    </Modal>
  );
};

export default SubscribeFormModal;
