import { is_logined_aotm, userInfoAtom } from '@/store/auth';
import { useAtom, useAtomValue } from 'jotai';

export function useLoginState(): [
  { isOpen: boolean; onOpen: () => void; onClose: () => void },
  (value: boolean) => void,
] {
  const [isOpen, setIsOpen] = useAtom(is_logined_aotm);
  const user_info = useAtomValue(userInfoAtom);

  const onClose = () => {
    setIsOpen(false);
  };
  const onOpen = () => {
    if (user_info !== null) {
      return;
    }
    setIsOpen(true);
  };
  const onToggle = (value: boolean) => setIsOpen(value);

  return [{ isOpen, onClose, onOpen }, onToggle];
}
