import { getUserDetail } from '@/api/usersApi';
import { userInfoAtom } from '@/store/auth';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export const useGetUserDetail = () => {
  const user_info = useAtomValue(userInfoAtom);

  return useQuery({
    queryKey: ['user_detail', user_info?.id],
    queryFn: async () => {
      const response = await getUserDetail(user_info!.id);
      return response;
    },
    enabled: user_info !== null,
    select: (data) => data.data,
  });
};
