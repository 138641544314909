import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

interface UserInfo {
  id: number;
  name: string;
  email: string;
  accessToken: string;
  isSubscribing: boolean;
}

export const userInfoAtom = atomWithStorage<UserInfo | null>('PACKINGLAB_INFO', null);

export const is_logined_aotm = atom(false);

export const is_card_modal_atom = atom(false);
